import axios from "axios";
import qs from "qs";
import { MsalAuthProvider } from "react-aad-msal";
import { useDispatch, useSelector } from "react-redux";
import secureLocalStorage from "react-secure-storage";
import {
  loginAzure,
  logoutAzure,
  setConfig,
  setUpGoogleProvider,
} from "store/actions";
import AzureSelector from "store/selectors/login_azure";
import GoogleSelector from "store/selectors/login_google";
import { DialogActionCreators } from 'store/redux/DialogAlert';
import { useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import AzureSelector from "store/selectors/login_azure"
// import GoogleSelector from "store/selectors/login_google"
// import { loginAzure, setConfig, logoutAzure, setUpGoogleProvider } from "store/actions";
// import { MsalAuthProvider } from "react-aad-msal"
export const AlertTitle = {
  Success: "Action Completed",
  Warning: "Warning",
  Error: "Error",
  Info: "Information",
  Confirm: "Confirmation",
  Hint: "Hint",
  Duplicate: "Duplicate",
};

export const AlertMsg = {
  SaveComplete: "Data is already saved.",
  DeleteComplete: "Data is already deleted.",
  Warning: "Warning",
  Error: "Some thing went wrong",
  Info: "Info",
  ConfirmSave: "Do you want to save data?",
  ConfirmSaveDraft: "Do you want to save draft data?",
  ConfirmDelete: "Do you want to delete data?",
  ConfirmApprove: "Do you want to approve data?",
  ConfirmRevisit: "Do you want to revisit data?",
  ConfirmCancel: "Do you want to cancel data?",
  ConfirmReject: "Do you want to reject data?",
  NoPermission: "Access denied",
  SessionTimeOut: "Please Log in ",
  Duplicate: "Duplicate Data",
  DeleteFail: 'Please select <i class="far fa-check-square"></i>',
  UploadImage: "Please upload image",
  Permission: "Permission Alert",
};

export const GetPermission = (nMenuID, setPermission, fncDialog = () => { }) => {
  // const navigate = useNavigate();
  let param = { nMenuID: nMenuID };
  AxiosPost(`Home/Permission`, param, (res) => {
    if (res.nPermission === 0) {
      fncDialog();
    }
    setPermission(res.nPermission);
  });
};

let xsrfToken = "";
//Get Antiforgery Token
export const GetAntiforgeryToken = async () => {
  let isPass = false;

  const baseUrl = process.env.REACT_APP_API_URL;
  const sPathApi = `${baseUrl}antiforgery/token`;
  const url = new URL(sPathApi, window.location.href);
  const sNewPath = url.origin + url.pathname + url.search;
  let localJwtKey = process.env.REACT_APP_JWT_KEY;
  let auth_token =
    secureLocalStorage.getItem(localJwtKey) != null
      ? (secureLocalStorage.getItem(localJwtKey) as string)
      : undefined;

  let config = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: IsNullOrUndefined(auth_token) ? "" : `Bearer ${auth_token}`,
  } as any;

  try {
    ///let Config = { Authorization: `Bearer ${auth_token}` } as any;
    const response = await axios.get(sNewPath, {
      withCredentials: true,
      headers: config,
    });

    // // if (response.status === 200) {
    // //   isPass = true;
    // //   xsrfToken = response.data.token;
    // // } else {
    // //   console.log("catch");
    // //   console.log("token status: ", response.status);
    // // }
    
    xsrfToken = response.data.token;
    console.log("token status: ", response.status);
    
    return {
      isPass: response.status === 200,
      xsrfToken: response.data.token,
    };
  } catch (error) {
    console.log("catch");
    console.log("token error", error);

    return {
      isPass: false,
      xsrfToken: "",
    }
  }
};

export const AxiosPost = async (
  sWebMetodName: string,
  objJSON,
  fnSuccess?,
  fnError?,
  fnComplete?
) => {

  //// await GetAntiforgeryToken();

  const objAntiforgeryToken = (await GetAntiforgeryToken()) as any;
  if (objAntiforgeryToken.isPass) {

    let localJwtKey = process.env.REACT_APP_JWT_KEY;

    let auth_token = secureLocalStorage.getItem(localJwtKey) != null
      ? (secureLocalStorage.getItem(localJwtKey) as string)
      : undefined;

    let ConfigHeader = {
      Accept: "application/json",
      "Content-Type": "application/json",
      // "Company-Token": `${localJwtKey}`,
      Authorization: IsNullOrUndefined(auth_token) ? "" : `Bearer ${auth_token}`,

      // For Guest (GuestController CreateProfile)
      "X-Signature": IsNullOrUndefined(auth_token) ? process.env.REACT_APP_API_KEY : ``,
    };
    const source = axios.CancelToken.source();
    axios.defaults.withCredentials = true;
    axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
    axios.defaults.headers.post["X-CSRF-TOKEN"] = objAntiforgeryToken.xsrfToken;

    /// const newParam = sWebMetodName;
    const baseUrl = process.env.REACT_APP_API_URL;
    const sPathApi = `${baseUrl}api/${sWebMetodName}`;
    const url = new URL(sPathApi, window.location.href);
    const sNewPath = url.origin + url.pathname + url.search;
    // // if (!CheckResourceInjection(sNewPath)) {
    // //   return;
    // // }
    await axios
      .post(sNewPath, objJSON, {
        headers: ConfigHeader,
        cancelToken: source.token,
      })
      .then((res) => {
        switch (res.status) {
          case 200:
            if (fnSuccess) fnSuccess(res.data);
            break;
          case 401:
            Unauthorize();
            break;
          case 404:
          case 500:
            break;
          default:
            if (fnError) fnError(res.data);
            break;
        }
      })
      .catch((errors) => {
        if (errors && errors.response && errors.response.status === 401) {
          // secureLocalStorage.clear();
          Unauthorize();
        }
        if (fnError) {
          fnError(errors.response.data || "");
        } else {
          console.error(errors);
        }
      })
      .finally(() => {
        if (fnComplete) fnComplete();
      });

  }
};
export const AxiosPostCreateProfile = async (
  sWebMetodName: string,
  sToken: string,
  objJSON,
  fnSuccess?,
  fnError?,
  fnComplete?
) => {
  await GetAntiforgeryToken();
  let localJwtKey = process.env.REACT_APP_JWT_KEY as string;
  let auth_token = sToken;
  let ConfigJwt = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Company-Token": `${localJwtKey}`,
    Authorization: IsNullOrUndefined(auth_token) ? "" : `Bearer ${auth_token}`,
    "X-Signature": IsNullOrUndefined(auth_token)
      ? process.env.REACT_APP_API_KEY
      : ``, // For Guest (GuestController CreateProfile)
  };

  //// axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
  //// axios.defaults.headers.post["X-CSRF-TOKEN"] = xsrfToken;

  const newParam = sWebMetodName;
  const baseUrl = process.env.REACT_APP_API_URL;
  const sPathApi = `${baseUrl}api/${newParam}`;
  const url = new URL(sPathApi, window.location.href);

  const sNewPath = url.origin + url.pathname + url.search;
  if (!CheckResourceInjection(sNewPath)) {
    return;
  }
  await axios
    .post(sNewPath, objJSON, { headers: ConfigJwt })
    .then((res) => {
      switch (res.status) {
        case 200:
          if (fnSuccess) fnSuccess(res.data);
          break;
        case 401:
          Unauthorize();
          break;
        case 404:
        case 500:
          break;
        default:
          if (fnError) fnError(res.data);
          break;
      }
    })
    .catch((errors) => {
      if (errors && errors.response && errors.response.status === 401) {
        // secureLocalStorage.clear();
        Unauthorize();
      }
      if (fnError) {
        fnError(errors.response.data);
      } else {
        console.error(errors);
      }
    })
    .finally(() => {
      if (fnComplete) fnComplete();
    });
};
export const AxiosPostReport = async (
  sWebMetodName: string,
  objJSON,
  fnSuccess?,
  fnError?,
  fnComplete?
) => {
  await GetAntiforgeryToken();

  // let localJwtKey = process.env.REACT_APP_JWT_KEY as string;
  let localJwtKey = process.env.REACT_APP_JWT_KEY as string;
  let auth_token =
    secureLocalStorage.getItem(localJwtKey) != null
      ? (secureLocalStorage.getItem(localJwtKey) as string)
      : undefined;
  let ConfigJwt = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Company-Token": `${localJwtKey}`,
    Authorization: IsNullOrUndefined(auth_token) ? "" : `Bearer ${auth_token}`,
    // responseType: "blob", // important
  };

  // const sPathApi = getURLAxios(sWebMetodName);
  // const sPathApi = process.env.REACT_APP_API_URL + "api/" + sWebMetodName;

  const newParam = sWebMetodName;
  const baseUrl = process.env.REACT_APP_API_URL;
  const sPathApi = `${baseUrl}api/${newParam}`;
  const url = new URL(sPathApi, window.location.href);

  const sNewPath = url.origin + url.pathname + url.search;
  if (!CheckResourceInjection(sNewPath)) {
    return;
  }

  await axios
    .post(sNewPath, objJSON, { headers: ConfigJwt, responseType: "blob" })
    .then((res) => {
      switch (res.status) {
        case 200:
          if (fnSuccess) fnSuccess(res.data);
          break;
        case 401:
          Unauthorize();
          break;
        case 404:
        case 500:
          break;
        default:
          if (fnError) fnError(res.data);
          break;
      }
    })
    .catch((errors) => {
      // console.error(errors);
      if (fnError) {
        fnError(errors);
      } else {
        console.error(errors);
      }
    })
    .finally(() => {
      if (fnComplete) fnComplete();
    });
};

export const AxiosGet = async (
  sWebMetodName: string,
  objJSON,
  fnSuccess?,
  fnError?,
  fnComplete?
) => {
  // await GetAntiforgeryToken();
  const localJwtKey = process.env.REACT_APP_JWT_KEY as string;
  const auth_token = secureLocalStorage.getItem(localJwtKey) != null ? (secureLocalStorage.getItem(localJwtKey) as string) : undefined;
  const ConfigJwt = {
    Authorization: IsNullOrUndefined(auth_token) ? "" : `Bearer ${auth_token}`,
  };

  axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
  axios.defaults.headers.post["X-CSRF-TOKEN"] = xsrfToken;

  const newParam = sWebMetodName;
  const baseUrl = process.env.REACT_APP_API_URL;
  const sPathApi = `${baseUrl}api/${newParam}`;
  const url = new URL(sPathApi, window.location.href);

  const sNewPath = url.origin + url.pathname + url.search;
  if (!CheckResourceInjection(sNewPath)) {
    return;
  }

  // .get(sNewPath, {
  //   headers: ConfigJwt,
  //   params: objJSON,
  //   paramsSerializer: (params) => {
  //     return qs.stringify(params);
  //   },
  // })
  axios
    .get(sNewPath, { headers: ConfigJwt, params: objJSON })
    .then((res) => {

      switch (res.status) {
        case 200:
          if (fnSuccess) fnSuccess(res.data);
          break;
        case 401:
          ///if (fnError) fnError("Please Login")
          Unauthorize();
          break;
        case 404:
        case 500:
          break;
        default:
          if (fnError) fnError(res.data);
          break;
      }
    })
    .catch((errors) => {
      ResultAPI(errors, "");
    })
    .finally(() => {
      if (fnComplete) fnComplete();

    });
};

export const AxiosGetAsync = async (
  sWebMetodName: string,
  objJSON,
  fnSuccess?,
  fnError?,
  fnComplete?
) => {
  await GetAntiforgeryToken();

  // let localJwtKey = process.env.REACT_APP_JWT_KEY as string;
  let localJwtKey = process.env.REACT_APP_JWT_KEY as string;
  let auth_token =
    secureLocalStorage.getItem(localJwtKey) != null
      ? (secureLocalStorage.getItem(localJwtKey) as string)
      : undefined;
  let ConfigJwt = {
    Authorization: IsNullOrUndefined(auth_token) ? "" : `Bearer ${auth_token}`,
  };

  axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
  axios.defaults.headers.post["X-CSRF-TOKEN"] = xsrfToken;

  const newParam = sWebMetodName;
  const baseUrl = process.env.REACT_APP_API_URL;
  const sPathApi = `${baseUrl}api/${newParam}`;
  const url = new URL(sPathApi, window.location.href);

  const sNewPath = url.origin + url.pathname + url.search;
  if (!CheckResourceInjection(sNewPath)) {
    return;
  }

  const response = await axios.get(sNewPath, {
    headers: ConfigJwt,
    params: objJSON,
  });
  return response.data;
};

export const AxiosPost_APIAsync = async (
  sWebMetodName: string,
  objJSON,
  fnSuccess?,
  fnError?,
  fnComplete?
) => {
  await GetAntiforgeryToken();

  // let localJwtKey = process.env.REACT_APP_JWT_KEY as string;
  let localJwtKey = process.env.REACT_APP_JWT_KEY as string;
  let auth_token =
    secureLocalStorage.getItem(localJwtKey) != null
      ? (secureLocalStorage.getItem(localJwtKey) as string)
      : undefined;
  let ConfigJwt = {
    Authorization: IsNullOrUndefined(auth_token) ? "" : `Bearer ${auth_token}`,
  };

  axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
  axios.defaults.headers.post["X-CSRF-TOKEN"] = xsrfToken;

  const newParam = sWebMetodName;
  const baseUrl = process.env.REACT_APP_API_URL;
  const sPathApi = `${baseUrl}api/${newParam}`;
  const url = new URL(sPathApi, window.location.href);

  const sNewPath = url.origin + url.pathname + url.search;
  if (!CheckResourceInjection(sNewPath)) {
    return;
  }

  const response = await axios.get(sNewPath, {
    headers: ConfigJwt,
    params: objJSON,
  });
  return response.data;
};

export const ResultAPI = (response, MSG_Success, fnOnSuccess?, fnOther?) => {
  let { status, sMsg, nPermission } = response;

  if (status === 200) {
    // if (!nPermission) nPermission = 1;
    // if (nPermission === 0) {
    //   // NoPermission();
    // } else {
    //   // if (!IsLoadData) {
    //   //   if (!MSG_Success) MSG_Success = AlertMsg.SaveComplete;
    //   //   SwAlert.Success(AlertTitle.Info, MSG_Success, fnOnSuccess);
    //   // } else {
    if (fnOnSuccess) fnOnSuccess();
    //   // }
    // }
  } else if (status === 401) {
    // SwAlert.Warning(AlertTitle.Warning, AlertMsg.SessionTimeOut, function () {
    //   var sPathSite = process.env.REACT_APP_API_URL as string;
    //   var sFullPath = window.location.origin  + window.location.pathname;
    //   var sPath = sFullPath.replace(sPathSite+"/", "");
    //   var sSearch = window.location.search;
    //   if (sSearch !== "") {
    //     sSearch = sSearch.replace("?", "&&");
    //   }
    //   var sPage = sPathSite + "?sPage=" + sPath + sSearch;
    //   window.location.href =  sPage;
    // });
  } else if (status === 403) {
    // SwAlert.Warning(AlertTitle.Warning, AlertMsg.SessionTimeOut, function () {
    //   var sPathSite = process.env.REACT_APP_API_URL as string;
    //   var sFullPath = window.location.origin  + window.location.pathname;
    //   var sPath = sFullPath.replace(sPathSite+"/", "");
    //   var sSearch = window.location.search;
    //   if (sSearch !== "") {
    //     sSearch = sSearch.replace("?", "&&");
    //   }
    //   var sPage = sPathSite + "?sPage=" + sPath + sSearch;
    //   window.location.href =  sPage;
    // });
  } else if (status === 409) {
    let tempMsg = AlertMsg.Duplicate;
    if (!sMsg) {
      sMsg = tempMsg;
    }
    // SwAlert.Error(AlertTitle.Warning, sMsg, () => {
    //   if (fnOther) fnOther();
    // });
  } else {
    if (fnOnSuccess) fnOnSuccess();
  }
};
export const OnFocus = (CtrlID1, CtrlID2) => {
  let CTRL1 = document.getElementById(CtrlID1);
  let CTRL2 = document.getElementById(CtrlID2);
  if (CTRL1) {
    CTRL1.addEventListener("keydown", function (e) {
      if (e.keyCode === 13) {
        //keyCode - ENTER : 13
        if (CTRL2) {
          CTRL2.click();
          return false;
        }
      } else if (e.keyCode === 220) return false; //keyCode - BACKSLASH : 220
    });
  }
};

export const Unauthorize = () => {
  console.log("Please Login");
  // const dispatch = useDispatch();
  // dispatch(DialogActionCreators.OpenDialogWarning("Please Login.", () => { }) as any)
};

export const IsNullOrUndefined = (Value) => {
  let Result = true;
  if (Value) {
    Result = false;
  }
  return Result;
};

export const ToInt = (Value) => {
  let Result = 0;
  if (Value) {
    Result = parseInt(Value);
  }
  return Result;
};

export const Encrypt = async (sValue) => {
  let Result = "";
  Result = await AxiosGetAsync("System/Encrypt", { sValue: sValue });
  return Result;
};
export function replaceDiacritics(s) {
  var diacritics = [
    /[\300-\306]/g,
    /[\340-\346]/g, // A, a
    /[\310-\313]/g,
    /[\350-\353]/g, // E, e
    /[\314-\317]/g,
    /[\354-\357]/g, // I, i
    /[\322-\330]/g,
    /[\362-\370]/g, // O, o
    /[\331-\334]/g,
    /[\371-\374]/g, // U, u
    /[\321]/g,
    /[\361]/g, // N, n
    /[\307]/g,
    /[\347]/g, // C, c
  ];

  var chars = [
    "A",
    "a",
    "E",
    "e",
    "I",
    "i",
    "O",
    "o",
    "U",
    "u",
    "N",
    "n",
    "C",
    "c",
  ];

  for (var i = 0; i < diacritics.length; i++) {
    s = s.replace(diacritics[i], chars[i]);
  }

  return s;
}

export const Extension = {
  Image: ["jpg", "jpeg", "png", "gif"],
  //Video: ["mov", "wmv", "avi", "mp4"],
  Video: ["mp4"],
  PDF: ["pdf"],
  Document: ["doc", "docx", "xls", "xlsx", "txt"],
  Word: ["doc", "docx"],
  Excel: ["xls", "xlsx"],
  Powpoint: ["pptx", "pdf", "ppt"],
  txt: ["txt"],
  Email: ["msg"],
  Other: ["rar", "zip"],
  ImagePDF: ["jpg", "jpeg", "png", "gif", "pdf"],
  DocumentWordPPt: ["docx", "pptx", "doc", "ppt", "pdf"],
  AllType: null,
};

export const HandleFormSubmitError = (errors, e) => {
  let isScroll = false;
  let height = 0;
  // Object.keys(errors).forEach((propName) => {
  //   let item = errors[propName]["ref"];
  //   if (item instanceof HTMLElement) {
  //     // || item instanceof Node
  //     if (height == 0 || (height > 0 && height > item.offsetHeight)) {
  //       height = item.offsetHeight;
  //       item.scrollIntoView({
  //         behavior: "smooth",
  //         block: "center",
  //       });
  //       isScroll = true;
  //     }
  //   }
  // });

  // if (!isScroll) {
  setTimeout(() => {
    let classErr = document.querySelectorAll("div.Mui-error");
    classErr[0].scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
    isScroll = true;
  }, 200);
  // }
  return isScroll;
  // errors[Object.keys(errors)[0]]["ref"].scrollIntoView({
  //   behavior: "smooth",
  //   block: "center",
  // });
};

export const AzureFunction = () => {
  const Dispatch = useDispatch();
  const AzureProvider = useSelector(
    AzureSelector.getProviderMsal
  ) as MsalAuthProvider;
  const LoginAzure = () => Dispatch(loginAzure() as any);
  const LogoutAzure = () => Dispatch(logoutAzure() as any);
  const SetConfigAzure = (sClientId, sTenantId) =>
    Dispatch(setConfig(sClientId, sTenantId));
  const GetStateAzure = () => AzureProvider.authenticationState;
  const GetAccountAzure = () => AzureProvider.getAccount();
  const GetConfigAzure = () => AzureProvider.getCurrentConfiguration();
  return {
    LoginAzure,
    SetConfigAzure,
    LogoutAzure,
    GetStateAzure,
    GetAccountAzure,
    GetConfigAzure,
  };
};

export const GoogleFunction = () => {
  const Dispatch = useDispatch();
  const GoogleProvider = useSelector(GoogleSelector.getProvider);
  const SetUpGoogleProvider = (provider) =>
    Dispatch(setUpGoogleProvider(provider));
  const LoginGoogle = () => GoogleProvider.signIn();
  const LogoutGoogle = () => GoogleProvider.signOut();
  const GetAccountGoogle = () => GoogleProvider.currentUser.get();
  const GetSignIned = () => GoogleProvider.isSignedIn.get();
  return {
    SetUpGoogleProvider,
    LoginGoogle,
    GetAccountGoogle,
    LogoutGoogle,
    GetSignIned,
  };
};

export const AxiosPostFilePDF = async (
  FileName,
  sWebMetodName: string,
  objJSON,
  fnSuccess?,
  fnError?,
  fnComplete?
) => {
  await GetAntiforgeryToken();

  // let localJwtKey = process.env.REACT_APP_JWT_KEY as string;
  let localJwtKey = process.env.REACT_APP_JWT_KEY as string;
  let auth_token =
    secureLocalStorage.getItem(localJwtKey) != null
      ? (secureLocalStorage.getItem(localJwtKey) as string)
      : undefined;
  let ConfigJwt = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Company-Token": `${localJwtKey}`,
    Authorization: IsNullOrUndefined(auth_token) ? "" : `Bearer ${auth_token}`,
  };

  axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
  axios.defaults.headers.post["X-CSRF-TOKEN"] = xsrfToken;

  const newParam = sWebMetodName;
  const baseUrl = process.env.REACT_APP_API_URL;
  const sPathApi = `${baseUrl}api/${newParam}`;
  const url = new URL(sPathApi, window.location.href);

  const sNewPath = url.origin + url.pathname + url.search;
  if (!CheckResourceInjection(sNewPath)) {
    return;
  }

  await axios
    .post(sNewPath, objJSON, { headers: ConfigJwt, responseType: "blob" })
    .then((res) => {
      switch (res.status) {
        case 200:
          if (fnSuccess) fnSuccess(res.data);
          break;
        case 401:
          Unauthorize();
          break;
        case 404:
        case 500:
          break;
        default:
          if (fnError) fnError(res.data);
          break;
      }
    })
    .catch((errors) => {
      if (errors && errors.response && errors.response.status === 401) {
        // secureLocalStorage.clear()
      }
      if (fnError) {
        fnError(errors);
      } else {
        console.error(errors);
      }
    })
    .finally(() => {
      if (fnComplete) fnComplete();
    });
};

export const CheckResourceInjection = (sUrl) => {
  let Result = true;
  var r = new RegExp(/^(ftp|http|https):\/\/[^ "]+$/);
  Result = r.test(sUrl);
  return Result;
};


function refreshAccessToken() {
  throw new Error("Function not implemented.");
}

function axiosApiInstance(originalRequest: any) {
  throw new Error("Function not implemented.");
}

function dispatch(arg0: any) {
  throw new Error("Function not implemented.");
}
// const getURLAxios = (sWebMetodName: string) => {
//   const newParam = sWebMetodName;
//   const baseUrl = process.env.REACT_APP_API_URL
//   const sPathApi = `${baseUrl}api/${newParam}`;
//   const url = new URL(sPathApi, window.location.href);
//   const sNewPath = url.origin + url.pathname + url.search;
//   return sNewPath;
// }
