import { useState } from "react";
import { Container, Typography, Stack, Box, TextField } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { BtnCustomIcon, BtnSubmit } from "components/Common/Button"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as yup from "yup";
import yupFormSchemas from "components/Common/yup/yupFormSchemas";
import DatePickerFormItem from 'components/Common/ElementInForm/DatePickerFormItem';

export default function DemoComponent() {
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const [valDate, SetValDate] = useState(null);
  const objSchema = {
    dEnd_Date: yupFormSchemas.string("วันที่สิ้นสุด", { required: false }),
    //     inp_required: yupFormSchemas.integer("number", { required: true }),
    // inp_requiredDecimal: yupFormSchemas.decimal("number decimal", { required: true }),
    // inp_DefaultValue: yupFormSchemas.integer("number default", {
    //   required: false,
    // }),
    // inp_DefaultValueDecimal: yupFormSchemas.decimal("number decimal default", {
    //   required: false,
    // }),
  };

  const schema = yup.object().shape(objSchema);
  const form = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    shouldFocusError: true,
    defaultValues: {
      inp_DefaultValue: 0,
      inp_DefaultValueDecimal: 1000,
    } as any,
  });
  const _handleSubmit = (e: any) => {
    // console.log(e)
    // console.log("MATCH => " + isBigScreen);

    // AxiosPost("Guest/TestNaja", {}, () => {
    //   console.log("SUCCESS");
    // });
  }
  return (
    <Container>
      <Stack direction={"column"} gap={2}>
        <Stack direction={"row"}>
          <Typography variant="h5" component={"label"}>
            Demo Component
          </Typography>
        </Stack>
        <FormProvider {...form}>
          <Stack direction={"column"}>
            <Stack>
              <DatePickerFormItem
                name={"dDate"}
                label={"Test Date"}
                fullWidth
                required
              />
            </Stack>
            <Stack>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Basic example"
                  value={valDate}
                  onChange={(newValue) => {
                    SetValDate(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Stack>
            {/* <Stack direction={"row"}>
              <Typography variant="h6" component={"label"}>
                Input number not required.
              </Typography>
            </Stack>

            <Box my={0.5} />
            <Stack direction={"row"}>
              <InputNumber label={"Integer"} name="inp_notRequired" />
            </Stack>
            <Box my={1} />
            <Stack direction={"row"}>
              <InputNumber label={"Decimal"} name="inp_DecimalnotRequired" decimalPoint={2} />
            </Stack>

            <Box my={1} />
            <Stack direction={"row"}>
              <Typography variant="h6" component={"label"}>
                Input number required.
              </Typography>
            </Stack>

            <Box my={0.5} />
            <Stack direction={"row"}>
              <InputNumber label={"Integer"} name="inp_required" required />
            </Stack>
            <Box my={1} />
            <Stack direction={"row"}>
              <InputNumber label={"Decimal"} name="inp_requiredDecimal" required decimalPoint={2} />
            </Stack>

            <Box my={1} />
            <Stack direction={"row"}>
              <Typography variant="h6" component={"label"}>
                Input number default value.
              </Typography>
            </Stack>

            <Box my={0.5} />
            <Stack direction={"row"}>
              <InputNumber label={"Integer"} name="inp_DefaultValue"  />
            </Stack>
            <Box my={1} />
            <Stack direction={"row"}>
              <InputNumber label={"Decimal"} name="inp_DefaultValueDecimal"  decimalPoint={2} />
            </Stack> */}

            <Box my={1} />
            <Stack direction={"row"} gap={1}>
              <BtnCustomIcon txt="Set value" onClick={() => {
                form.setValue("inp_required", 1000, { shouldValidate: true });
                form.setValue("inp_requiredDecimal", 1000.12, { shouldValidate: true });
              }} />
              <BtnCustomIcon txt="Reset value" onClick={() => {
                for (const [key, value] of Object.entries(form.getValues())) {
                  form.setValue(key, null, { shouldValidate: true });
                }
              }} />
              <BtnSubmit onClick={form.handleSubmit(_handleSubmit)} />
            </Stack>
          </Stack>
        </FormProvider>
      </Stack>
    </Container>
  );
}
