import { Box, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AxiosGet, ResultAPI } from "service/CommonFunction/TS_function";
import { Helmet } from 'react-helmet';
import secureLocalStorage from "react-secure-storage";
import ReactGTM from 'react-gtm-module';

const Navigate_Layout_BPS = ({ handleDrawerClose, handleDrawerOpen, open, children }) => {
  const location = useLocation();
  const [lstMenuNav, setlstMenuNav] = useState([]);
  const [selectIndex, setSelectIndex] = useState(lstMenuNav.length > 0 ? lstMenuNav.findIndex(f => location.pathname.includes(f.sUrl)) : 0);
  const [sName, setName] = useState("");
  const [sNameTH, setsNameTH] = useState(null);
  const [sNameGTM, setsNameGTM] = useState(null);
  const [sUrl, setsUrl] = useState(null);

  useEffect(() => {
    let localLang = "th".toLocaleUpperCase();
    if (lstMenuNav.length == 0) {
      AxiosGet("MenuBPS/SearchData_BackMenuBPS", {}, (result) => {
        ResultAPI(result, "", () => {
          setlstMenuNav(result.lstMenuNav);
          secureLocalStorage.setItem("IsBack", JSON.stringify(result.lstMenuNav))
          let indexSelected = result.lstMenuNav.findIndex(f => location.pathname.includes(f.sUrl))
          if (indexSelected != null) {
            setSelectIndex(indexSelected)
            let objMenu = result.lstMenuNav[indexSelected];
            if (objMenu != null) setName(objMenu[`sName${localLang}`]);

            setsNameTH(objMenu.sNameTH);
            if(objMenu.sNameTH !== sNameTH) {
              setsNameGTM(objMenu.sNameTH + " (" + objMenu.sNameEN + ")"); 
              setsUrl(objMenu.sUrl);
            }
          } else {
            setSelectIndex(0)
          }
        });
      });
    }
    else {
      let indexSelected = lstMenuNav.findIndex(f => location.pathname == (f.sUrl))
      if (indexSelected != null) {
        setSelectIndex(indexSelected)
        let objMenu = lstMenuNav[indexSelected];
        if (objMenu != null)
          setName(objMenu[`sName${localLang}`]);

          setsNameTH(objMenu.sNameTH);
          if(objMenu.sNameTH !== sNameTH) {
            setsNameGTM(objMenu.sNameTH + " (" + objMenu.sNameEN + ")"); 
            setsUrl(objMenu.sUrl);
          }
      } else {
        setSelectIndex(0)
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if(sNameGTM && sUrl)
    {
      ReactGTM.dataLayer({
        dataLayer: {
          event: sNameGTM,
          page: sUrl,
          title: sNameGTM,
        },
      });
    }
  }, [sNameGTM, sUrl])

  return (
    <Grid container>
      <Box sx={{ display: "none !important", visibility: "hidden" }}>
        <Helmet>
          <title> {sName} </title>
        </Helmet>
      </Box>
      <Grid item xs={12}>
        <Box sx={{
          p: 2, display: "flex", flex: 2, minHeight: "100%", borderRadius: 5, mx: "2%", boxShadow: 3,
          bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff')
        }}>
          <Stack direction="row" alignItems="center" spacing={1} sx={{ color: "black" }} >
            <Typography component="h5" variant="h5" sx={{ fontWeight: 600, color: "inherit" }} >
              {sName}
            </Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: "2%" }}>
        {children}
      </Grid>
    </Grid>
  );
}

export default Navigate_Layout_BPS