import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer_Layout from "./Drawer_Layout_BPS";
import Header_Layout from './Header_Layout_BPS';
import '../Layout_Back_BPS/BPSDrawer.css'
import Navigate_Layout_BPS from './Navigate_Layout_BPS';
import secureLocalStorage from 'react-secure-storage';

export default function Layout_Back_BPS(props) {
  const [open, setOpen] = React.useState(false);
  const [nFocusMenu, setFocusMenu] = React.useState(secureLocalStorage.getItem("sFocusIndex") && secureLocalStorage.getItem("sFocusIndex") != "" ? parseInt(secureLocalStorage.getItem("sFocusIndex").toString()) : 0)

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box className="DrawerHead" sx={{ display: 'flex' }}>
      <Header_Layout handleDrawerClose={handleDrawerClose} handleDrawerOpen={handleDrawerOpen} open={open} />
      <Drawer_Layout handleDrawerClose={handleDrawerClose} handleDrawerOpen={handleDrawerOpen} open={open} FocusMenu={nFocusMenu} />
      <Navigate_Layout_BPS  handleDrawerClose={handleDrawerClose} handleDrawerOpen={handleDrawerOpen} open={open} children={props.children}/>      
    </Box>
  );
}
