import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import { Avatar, Divider, Grid, Stack, } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { BtnLogout } from "components/Common/Button";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { useDispatch } from "react-redux";
import { HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import { useEffect, useState } from "react";
import { AzureFunction } from "service/CommonFunction/TS_function";
import { i18n } from "i18n";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import secureLocalStorage from 'react-secure-storage';
import LogoBPS from "assets/images/BPS-Logo.png";

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    width: "100%",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function Header_Layout_BPS({
  handleDrawerClose,
  handleDrawerOpen,
  open,
}) {
  const navigate = useNavigate();
  const dispatchRedux = useDispatch();
  const [ChangeButt, setButt] = useState<any>(); // for change login / logout button
  const [state, dispatch] = useState<any>();
  const { LogoutAzure, LoginAzure, GetStateAzure, GetAccountAzure, SetConfigAzure } = AzureFunction();

  const connection = new HubConnectionBuilder()
    .withUrl(process.env.REACT_APP_API_URL + "hubs/auth")
    .withAutomaticReconnect()
    .build();

  const _clearLocalStorageWithoutConnectId = () => {
    secureLocalStorage.removeItem("isBack");
    secureLocalStorage.removeItem("sUserId");
    secureLocalStorage.removeItem("sUserFullNameEN");
    secureLocalStorage.removeItem("sUserFullNameTH");
    secureLocalStorage.removeItem("nGroupType");
    secureLocalStorage.removeItem("sUserNickName");
    secureLocalStorage.removeItem(process.env.REACT_APP_JWT_KEY as string);
    secureLocalStorage.removeItem("sFocusIndex");
    secureLocalStorage.removeItem("connectionID_bps");
    // localStorage.removeItem("isBack");
    // localStorage.removeItem("sUserId");
    // localStorage.removeItem("sUserFullNameEN");
    // localStorage.removeItem("sUserFullNameTH");
    // localStorage.removeItem("nGroupType");
    // localStorage.removeItem("sUserNickName");
    // localStorage.removeItem(process.env.REACT_APP_JWT_KEY as string);
    // localStorage.removeItem("sSubRouteName");
    // localStorage.removeItem("sFocusIndex");
    secureLocalStorage.removeItem("/AdminOrder");
    secureLocalStorage.removeItem("/AdminCompany");
    secureLocalStorage.removeItem("/ManagePromotion1");
    secureLocalStorage.removeItem("/ManagePromotion2");
    secureLocalStorage.removeItem("/ShowListPackage1");
    secureLocalStorage.removeItem("/ShowListPackage2");
    secureLocalStorage.removeItem("/AdminCandidateTable");
    secureLocalStorage.removeItem("/AdminFree");
    secureLocalStorage.removeItem("/ContentTable");
    secureLocalStorage.removeItem("/AdminHoldPackage");
    secureLocalStorage.removeItem("/GroupTable_BPS");
    secureLocalStorage.removeItem("/AdminSpecial");
    secureLocalStorage.removeItem("/AdminUserTable");
    secureLocalStorage.removeItem("/AdminFreePackageApprove");
    secureLocalStorage.removeItem("/AdminPausePackage");
    secureLocalStorage.removeItem("/AdminCandidate");
    secureLocalStorage.removeItem("/AdminCandidate1");
    secureLocalStorage.removeItem("/AdminCandidate2");
    secureLocalStorage.removeItem("/AdminCandidate3");
    secureLocalStorage.removeItem("/AdminCandidate4");
    secureLocalStorage.removeItem("/AdminCompanyJob");
    secureLocalStorage.removeItem("/AdminCompanyBanner");
    secureLocalStorage.removeItem("/AdminCompanyOrder");
    secureLocalStorage.removeItem("/AdminCompanyMember");
    secureLocalStorage.removeItem("/JobPostList");
    secureLocalStorage.removeItem("/OrderEmployer1");
    secureLocalStorage.removeItem("/MemberEmployeeTable");
  };

  const _onReceiveLoginHub = (sConId, isLoginAgain) => {
    if (
      sConId == secureLocalStorage.getItem("connectionID_bps") &&
      secureLocalStorage.getItem("isSignOut_bps") != "1"
    ) {
      if (isLoginAgain) {
        dispatchRedux(DialogActionCreators.CloseDialogWarning() as any);
        window.location.reload();
      } else {
        dispatchRedux(
          DialogActionCreators.OpenDialogWarning(
            "กรุณาเข้าสู่ระบบอีกครั้ง",
            () => {
              // window.localStorage.clear();
              navigate("/");
            }
          ) as any
        );
      }
    }
  };

  useEffect(() => {
    if (connection.state != HubConnectionState.Connected) {
      connection
        .start()
        .then((result) => {
          connection.on("ReceiveLogin", (sConnectionId, isLogin) => {
            _onReceiveLoginHub(sConnectionId, isLogin);
            //เคลีย sessionStorage เก่าออก
            // secureLocalStorage.clear();
            secureLocalStorage.removeItem('isSignOut_bps');
            secureLocalStorage.removeItem('sUrlQrCode');
            secureLocalStorage.removeItem('isVerify');
          });
        })
        .catch((e) => {
          console.log("Connection failed: ", e);
        });
    } else {
      connection.on("ReceiveLogin", (sConnectionId, isLogin) => {
        _onReceiveLoginHub(sConnectionId, isLogin);
        //เคลีย sessionStorage เก่าออก
        // secureLocalStorage.clear();
        secureLocalStorage.removeItem('isSignOut_bps');
        secureLocalStorage.removeItem('sUrlQrCode');
        secureLocalStorage.removeItem('isVerify');
      });
    }
  }, []);

  const logOut = () => {
    dispatchRedux(
      DialogActionCreators.OpenDialogSubmit(i18n(`common.msgAlertLogout`), () => {
        dispatchRedux(DialogActionCreators.CloseDialogSubmit() as any);

        connection.send(
          "OnSignOutSignal",
          secureLocalStorage.getItem("connectionID_bps"),
          false
        );
        // เก็บค่าไว้สำหรับ handle กรณีใช้งานหลาย tab ใน 1 browser
        secureLocalStorage.setItem("isSignOut_bps", "1");
        _clearLocalStorageWithoutConnectId();

        if (GetAccountAzure()) { LogoutAzure() }
        navigate("/AdminLogin");
      }) as any
    );
  };

  return (
    <AppBar
      position="fixed"
      open={open}
      sx={{ backgroundColor: "white", minWidth: "100%" }}
    >
      <Toolbar sx={{ backgroundColor: "white", minWidth: "100%" }}>

        <Typography variant="h6" noWrap component="div" sx={{ color: "gray" }}>
          <img
            alt=""
            src={LogoBPS}
            width={50}
            height={50}
          />
        </Typography>

        <Typography sx={{ fontSize: "18px", color: "#484848" }} component="div">
          &nbsp;Administrator BPS
        </Typography>

        <Grid container justifyContent="flex-end" sx={{ flex: 1 }}>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{ marginRight: "2%" }}
          >
            <Avatar
              sx={{
                bgcolor: "#ffffff",
                border: "1px solid gray",
                color: "gray",
              }}
            >
              {/*window.localStorage.getItem("sName").substring(0, 1).toLocaleUpperCase()*/}
            </Avatar>
            <Stack
              sx={{
                b: {
                  "@media (min-width:831px)": {
                    maxWidth: "170px",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  },
                  "@media (max-width:830px)": {
                    maxWidth: "170px",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    display: open ? "none" : "flex",
                  },
                  "@media (max-width:660px)": {
                    display: "none",
                  },
                },
              }}
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Typography
                sx={{
                  color: "black",
                  fontSize: 18,
                  lineHeight: 1,
                  textTransform: "none",
                }}
                variant="body1"
                component="b"
              >
                {secureLocalStorage.getItem("sUserFullNameTH")}
                {/* {localStorage.getItem("sUserFullNameTH")} */}
              </Typography>
            </Stack>

            <Divider orientation="vertical" variant="middle" flexItem />
          </Stack>

          {/* logout button */}
          <BtnLogout
            txt="ออกจากระบบ"
            tooltipPlacement="bottom"
            isCircleWithOutText
            onClick={() => logOut()}
          />
        </Grid>
      </Toolbar>
    </AppBar>
  );
}